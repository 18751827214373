import nxModule from 'nxModule';

export class WatchlistService {
  constructor(confirmation, command, confirmationTemplate, popup, $filter) {
    this.confirmation = confirmation;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.popup = popup;
    this.$filter = $filter;
  }

  emptyVerification() {
    return {
      completed: false,
      outputCommandId: null,
      watchlistMatch: null,
      confirmed: false
    };
  }

  createCommandInput(verificationResult, customer) {
    return {
      watchlistVerificationCommandId: verificationResult.outputCommandId,
      watchlistMatch: verificationResult.watchlistMatch,
      confirmed: verificationResult.confirmed,
      customer: customer
    };
  }

  getCreateCustomerCommand(verificationResult) {
    if (!verificationResult || !verificationResult.completed) {
      return null;
    }

    if (!verificationResult.watchlistMatch || !verificationResult.confirmed) {
      return 'CreateCustomer';
    }

    return 'CreateWatchlistConfirmedCustomer';
  }

  async verify(verificationInput) {
    const verify = await this.confirmation('Do you want to proceed with verification?');
    if (!verify) {
      return;
    }

    const response = await this.command.execute('CustomerCheckWatchlist', verificationInput).toPromise();
    if (response.approvalRequired) {
      throw Error('CustomerCheckWatchlist is not designed for approval')
    }

    const watchlistMatch = response.output.watchlistMatch;
    if (!watchlistMatch || watchlistMatch.length === 0) {
      this.popup({
        header: 'Watchlist verification',
        text: 'No match found.'
      });

      return {
        completed: true,
        outputCommandId: response.commandId
      }
    }

    const watchlist = watchlistMatch[0];
    const entry = watchlist.entries[0];

    const confirmed = await this.confirmationTemplate({
      question: `Found ${watchlistMatch.length} match(es). Is this correct?`,
      details: [
        {label: 'Watchlist name', description: watchlist.name},
        {label: 'Watchlist type', description: this.$filter('prettyEnum')(watchlist.watchlistType)},
        {label: 'Customer type', description: this.$filter('prettyEnum')(watchlist.customerType)},
        {label: 'Ref. no.', description: entry.refNo},
        entry.name1 ? {label: 'Name 1', description: entry.name1} : null,
        entry.name2 ? {label: 'Name 2', description: entry.name2} : null,
        entry.name3 ? {label: 'Name 3', description: entry.name3} : null,
        entry.name4 ? {label: 'Name 4', description: entry.name4} : null,
        entry.name5 ? {label: 'Name 5', description: entry.name5} : null,
        entry.name6 ? {label: 'Name 6', description: entry.name6} : null,
        entry.name7 ? {label: 'Name 7', description: entry.name7} : null,
        entry.name8 ? {label: 'Name 8', description: entry.name8} : null,
        entry.name9 ? {label: 'Name 9', description: entry.name9} : null,
        entry.name10 ? {label: 'Name 10', description: entry.name10} : null,
        entry.name11 ? {label: 'Name 11', description: entry.name11} : null,
        entry.name12 ? {label: 'Name 12', description: entry.name12} : null,
        entry.name13 ? {label: 'Name 13', description: entry.name13} : null,
        entry.name14 ? {label: 'Name 14', description: entry.name14} : null,
        entry.name15 ? {label: 'Name 15', description: entry.name15} : null,
      ].filter(exists => exists),
      yesCallback: () => Promise.resolve(true),
      noCallback: () => Promise.resolve(false),
    });

    if (!confirmed) {
      return {
        completed: true,
        outputCommandId: response.commandId,
        watchlistMatch: watchlist,
        confirmed: false
      }
    }

    return {
      completed: true,
      outputCommandId: response.commandId,
      watchlistMatch: watchlist,
      confirmed: true
    }
  }
}

nxModule.factory('watchlistService', (confirmation, command, confirmationTemplate, popup, $filter) =>
  new WatchlistService(confirmation, command, confirmationTemplate, popup, $filter)
);
import nxModule from 'nxModule';
import _ from 'lodash';

import './risk-category-details.style.less';
import systemPropertyService from 'system/systemPropertyService';
import templateUrl from './risk-category-details.template.html';

class RiskCategoryDetailsView {
  constructor(http, $route, breadcrumbs, confirmation, $location, dict, popup, command) {
    this.http = http;
    this.$route = $route;
    this.breadcrumbs = breadcrumbs;
    this.confirmation = confirmation;
    this.$location = $location;
    this.dict = dict;
    this.popup = popup;
    this.command = command;
    this.systemPropertyService = systemPropertyService;
  }

  async fetchSelectedCategory() {
    this.category = await this.http.get(`/management/risk/categories/${this.categoryId}`).toPromise();
    this.calculateEntryScores();

    _.set(this.breadcrumbs, 'options.risk-category-label', (this.category || {}).name);
  }

  calculateEntryScores() {
    if (!this.category) {
      return;
    }

    this.category.entries = this.category.entries.map(
      e => ({
        ...e,
        score: this.calculateScore(e)
      })
    );
  }

  async $onInit() {
    [this.properties, this.propertyDefinitions, this.watchlists] = await Promise.all([
      this.systemPropertyService.getPropertyDescriptors(),
      this.http.get('/management/risk/categories/property-definitions').toPromise(),
      this.http.get('/management/watchlist').toPromise()
    ]);

    this.watchlists = this.watchlists.map(w => ({...w, label: w.name}));

    const corpoProp = _.find(this.properties, {code: 'CIF_CORPORATE_SUPPORT'});
    this.corporateCustomerEnabled = corpoProp && corpoProp.value === 'TRUE';

    this.dict.onLoadingComplete(() => {
      this.highRiskDictionaryEntryId = this.dict.getId('CUSTOMER_RISK_LEVEL', 'HIGH');
      this.riskDictionaryEntries = this.dict['CUSTOMER_RISK_LEVEL'];
    });

    this.categoryId = this.$route.current.params['riskCategoryId'];

    await this.initializeCategory();
    this.setAvailableCustomerTypes();
    this.assignToOption = [
      {
        value: 'NONE',
        label: 'None'
      },
      {
        value: 'SUBSTITUTED_VALUE',
        label: 'Substituted Value'
      },
      {
        value: 'LINKED_CONDITIONS',
        label: 'Linked Conditions'
      },
    ];
    this.selectionTypeOption = [
      {
        value: 'SINGLE_SELECT',
        label: 'Single select'
      },
      {
        value: 'MULTI_SELECT',
        label: 'Multi-select'
      },
    ];
  }

  async initializeCategory() {
    if (this.categoryId) {
      await this.fetchSelectedCategory();
    } else {
      this.category = {
        weightPercentage: 0,
        entries: []
      };
    }
  }

  setAvailableCustomerTypes() {
    this.availableCustomerTypes = [
      {
        value: 'INDIVIDUAL',
        label: 'Individual'
      }
    ];

    if (this.corporateCustomerEnabled) {
      this.availableCustomerTypes.push(
        {
          value: 'CORPORATE',
          label: 'Corporate'
        }
      )
    }
  }

  isMainSaveDisabled() {
    return this.form.$invalid || this.form.$pristine;
  }

  getForm(index) {
    return this[`form_${index}`];
  }

  isSaveDisabled(index) {
    return this.getForm(index).$invalid || this.getForm(index).$pristine || this.getForm(index).$submitted
  }

  isAutoHighRiskDisabled(e) {
    return e.riskDictionaryEntryId !== this.highRiskDictionaryEntryId;
  }

  onRiskLevelChange(e) {
    if (this.isAutoHighRiskDisabled(e)) {
      e.autoHighRisk = false;
    }
  }

  async upsertCategory(action, command) {
    const confirmation = await this.confirmation(`Do you want to ${action} following risk category?`);
    if (!confirmation) {
      return;
    }

    if (command === 'CreateRiskCategory') {
      this.category.entries = [];
    }

    const response = await this.command.execute(command, {category: this.category}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$location.path(`/admin/compliance/risk/categories/${response.output.id}`);
    this.$route.reload();
  }

  async uploadSubstitutedValues() {
    const confirmation = await this.confirmation(`Do you want to apply uploaded file to substituted values in sub-categories?`);
    if (!confirmation) {
      return;
    }

    const command = this.category.id ? 'UpdateRiskCategory' : 'CreateRiskCategory';
    let response = await this.command.execute(command, {category: this.category}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    const fileId = this.uploadedFiles ? this.uploadedFiles[0].id : null;
    const riskCategoryId = response.output.id ? response.output.id : this.categoryId;
    response = await this.command.execute('ApplySubstitutedValues', {fileId: fileId, riskCategoryId: riskCategoryId}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$location.path(`/admin/compliance/risk/categories/${riskCategoryId}`);
    this.$route.reload();
  }

  goBack() {
    this.$location.path(`/admin/compliance/risk/categories/`);
  }

  addEntry() {
    this.category.entries.push({
      riskCategoryId: this.category.id,
      conditions: {},
      autoHighRisk: false
    });
  }

  async removeEntry(idx) {
    const entry = this.category.entries[idx];
    if (entry.id) {
      const confirmation = await this.confirmation('Do you want to remove this entry?');
      if (!confirmation) {
        return;
      }

      const response = await this.command.execute('DeleteRiskCategoryEntry', {id: entry.id}).toPromise();
      if (response.approvalRequired) {
        return;
      }
    }

    await this.fetchSelectedCategory();
  }

  async upsertEntry(idx, action, command) {
    const confirmation = await this.confirmation(`Do you want to ${action} this risk category entry?`);
    if (!confirmation) {
      return;
    }

    const entry = _.cloneDeep(this.category.entries[idx]);
    entry.conditions = {json: JSON.stringify(entry.conditions)};

    const response = await this.command.execute(command, {entry: entry}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.getForm(idx).$setSubmitted();
    await this.fetchSelectedCategory();
  }

  async saveAllEntries() {
    if (this.category.assignTo === 'LINKED_CONDITIONS'
      && this.category.entries.some(e => JSON.stringify(e.conditions) === '{}')) {
      this.popup({
        text: `Main category is assigned to Linked Conditions, each sub-category should be assigned a Linked Condition`,
        header: 'Error'
      });
      return;
    }

    const confirmation = await this.confirmation(`Do you want to save all changes in main category and sub-categories?`);
    if (!confirmation) {
      return;
    }

    let response = await this.command.execute('UpdateRiskCategory', {category: this.category}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.category.entries = this.category.entries.map(e => {
      const entry = _.cloneDeep(e);
      if (this.category.assignTo === 'NONE') {
        entry.conditions = {};
      } else {
        entry.conditions = {json: JSON.stringify(e.conditions)};
      }
      return entry;
    });

    response = await this.command.execute('UpsertRiskCategoryEntries',
      {entries: this.category.entries}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }

  onWeightChange(idx) {
    const entry = this.category.entries[idx];
    entry.score = this.calculateScore(entry);
  }

  calculateScore(entry) {
    return this.category.weightPercentage / 100.0 * entry.weight;
  }

  addCondition(entry, propertyDefinition) {
    // first selected property is used as a key, results in having only one such property for whole condition
    if (entry.conditions[propertyDefinition.property]) return;

    entry.conditions[propertyDefinition.property] = {properties: [propertyDefinition.property]};
  }
}

nxModule.component('riskCategoryDetailsView', {
  templateUrl,
  controller: RiskCategoryDetailsView
});

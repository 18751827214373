import nxModule from 'nxModule';

import templateUrl from './risk-assessment.template.html';
import './risk-assessment.style.less';

class RiskAssessmentView {
  constructor(http, confirmation, $location, dict, command) {
    this.http = http;
    this.confirmation = confirmation;
    this.$location = $location;
    this.dict = dict;
    this.command = command;
  }

  async $onInit() {
    const entries = await this.http.get('/management/risk/assessment').toPromise();
    this.sortedEntries = entries.sort((a, b) => a.minPoints - b.minPoints); // 0 min points at the top

    this.dict.onLoadingComplete(() => {
      this.riskDictionaryEntries = this.dict['CUSTOMER_RISK_LEVEL'];
    });
  }

  isMinPointsGtPreviousMaxPoints(index) {
    const current = this.sortedEntries[index]
    if (index === 0) {
      return current.minPoints === 0;
    }

    const previous = this.sortedEntries[index - 1];
    return current.minPoints > previous.maxPoints;
  }

  isMaxPointsGteMinPoints(index) {
    const current = this.sortedEntries[index]
    return current.maxPoints >= current.minPoints;
  }

  areAllEntriesValid() {
    if (!this.sortedEntries) {
      return false;
    }

    for (let i = 0; i < this.sortedEntries.length; i++) {
      if (!this.isMinPointsGtPreviousMaxPoints(i)) {
        return false;
      }

      if (!this.isMaxPointsGteMinPoints(i)) {
        return false;
      }
    }

    return true;
  }

  async save() {
    const confirmation = await this.confirmation('Do you want to update it?');
    if (!confirmation) {
      return;
    }

    await this.command.execute('UpdateRiskAssessments', {entries: this.sortedEntries}).toPromise();
    this.goBack();
  }

  goBack() {
    this.$location.path('/admin/compliance')
  }
}

nxModule.component('riskAssessmentView', {
  templateUrl,
  controller: RiskAssessmentView
});
